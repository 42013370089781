import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/",
      name: "home",
      // lazy-loaded
      component: () => import("../views/Home.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      // lazy-loaded
      component: () => import("../views/Profile.vue"),
    },
    {
      path: "/hours",
      name: "hours",
      // lazy-loaded
      component: () => import("../views/Hours.vue"),
    },
    {
      path: "/vacations",
      name: "vacations",
      // lazy-loaded
      component: () => import("../views/Vacations.vue"),
    },
    {
      path: "/vacations-department",
      name: "vacations-department",
      // lazy-loaded
      component: () => import("../views/VacationsDepartments.vue"),
    },
    {
      path: "/vacations-approve",
      name: "vacations-approve",
      // lazy-loaded
      component: () => import("../views/VacationsApprove.vue"),
    },
    {
      path: "/expenditure/:status?",
      name: "expenditure",
      // lazy-loaded
      component: () => import("../views/Expenditure.vue"),
    },
    {
      path: "/expenditure-approve",
      name: "expenditure-approve",
      // lazy-loaded
      component: () => import("../views/ExpenditureApprove.vue"),
    },
    {
      path: "/miss",
      name: "miss",
      // lazy-loaded
      component: () => import("../views/Miss.vue"),
    },
    {
      path: "/miss-approve",
      name: "miss-approve",
      // lazy-loaded
      component: () => import("../views/MissApprove.vue"),
    },
    {
      path: "/support",
      name: "support",
      // lazy-loaded
      component: () => import("../views/Support.vue"),
    },
    {
      path: "/surveys-budgets",
      name: "surveys-budgets",
      // lazy-loaded
      component: () => import("../views/SurveysBudgets.vue"),
    },
    {
      path: "/surveys-to-close/:status?",
      name: "surveys-to-close",
      // lazy-loaded
      component: () => import("../views/SurveysToClose.vue"),
    },
    {
      path: "/surveys-closed",
      name: "surveys-closed",
      // lazy-loaded
      component: () => import("../views/SurveysClosed.vue"),
    },
    {
      path: "/surveys/:status?",
      name: "surveys",
      // lazy-loaded
      component: () => import("../views/Surveys.vue"),
    },
    // {
    //   path: "/user-pats",
    //   name: "user-pats",
    //   // lazy-loaded
    //   component: () => import("../views/UserPats.vue"),
    // },
    // {
    //   path: "/project-comments",
    //   name: "project-comments",
    //   // lazy-loaded
    //   component: () => import("../views/ProjectComments.vue"),
    // },
    {
      path: "/project-comments-view/:id",
      name: "project-comments-view",
      // lazy-loaded
      component: () => import("../views/ProjectCommentsView.vue"),
    },
    {
      path: "/installations-witchout-report",
      name: "installations-witchout-report",
      // lazy-loaded
      component: () => import("../views/InstallationsWitchoutReport.vue"),
    },
    {
      path: "/installations-witch-report",
      name: "installations-witch-report",
      // lazy-loaded
      component: () => import("../views/InstallationsWitchReport.vue"),
    },
    {
      path: "/task-installation-sheet/:taskId/:idInstallations?",
      name: "task-installation-sheet",
      // lazy-loaded
      component: () => import("../views/TaskInstallationSheet.vue"),
    },
    {
      path: "/terminal",
      name: "terminal",
      // lazy-loaded
      component: () => import("../views/Terminal.vue"),
    },
    {
      path: "/satisfaction-form-installations",
      name: "satisfaction-form-installations",
      // lazy-loaded
      component: () => import("../views/SatisfactionFormInstallations.vue"),
    },
    {
      path: "/satisfaction-form-installations-quiz/:id",
      name: "satisfaction-form-installations-quiz",
      // lazy-loaded
      component: () => import("../views/SatisfactionFormInstallationsQuiz.vue"),
    },
    {
      path: "/satisfaction-form-support",
      name: "satisfaction-form-support",
      // lazy-loaded
      component: () => import("../views/SatisfactionFormSupport.vue"),
    },
    {
      path: "/satisfaction-form-support-quiz/:id",
      name: "satisfaction-form-support-quiz",
      // lazy-loaded
      component: () => import("../views/SatisfactionFormSupportQuiz.vue"),
    },
    {
      path: "/users-info",
      name: "users-info",
      // lazy-loaded
      component: () => import("../views/UsersInfo.vue"),
    },
    {
      path: "/users-info-view/:id",
      name: "users-info-view",
      // lazy-loaded
      component: () => import("../views/UsersInfoView.vue"),
    },
    {
      path: "/satisfaction-form-installations-export",
      name: "satisfaction-form-installations-export",
      // lazy-loaded
      component: () =>
        import("../views/SatisfactionFormInstallationsExport.vue"),
    },
    {
      path: "/satisfaction-form-support-export",
      name: "satisfaction-form-support-export",
      // lazy-loaded
      component: () => import("../views/SatisfactionFormSupportExport.vue"),
    },
    {
      path: "/satisfaction-form-resume",
      name: "satisfaction-form-resume",
      // lazy-loaded
      component: () => import("../views/SatisfactionFormResume.vue"),
    },
    {
      path: "/disclosure",
      name: "disclosure",
      // lazy-loaded
      component: () => import("../views/Disclosure.vue"),
    },
    {
      path: "/disclosure-view/:id",
      name: "disclosure-view",
      // lazy-loaded
      component: () => import("../views/DisclosureView.vue"),
    },
    {
      path: "/installations-open-dossiers",
      name: "installations-open-dossiers",
      // lazy-loaded
      component: () => import("../views/InstallationsOpenDossiers.vue"),
    },
    {
      path: "/competency-profile",
      name: "competency-profile",
      // lazy-loaded
      component: () => import("../views/CompetencyProfile.vue"),
    },
    {
      path: "/competency-profile-resume",
      name: "competency-profile-resume",
      // lazy-loaded
      component: () => import("../views/CompetencyProfileResume.vue"),
    },
    {
      path: "/competency-profile-user",
      name: "competency-profile-user",
      // lazy-loaded
      component: () => import("../views/CompetencyProfileUser.vue"),
    },
    {
      path: "/competency-profile-user/:userId/:username",
      // lazy-loaded
      component: () => import("../views/CompetencyProfileUser.vue"),
    },
    {
      path: "/competency-profile-view/:id",
      name: "competency-profile-view",
      // lazy-loaded
      component: () => import("../views/CompetencyProfileView.vue"),
    },
    {
      path: "/useful-links",
      name: "useful-links",
      // lazy-loaded
      component: () => import("../views/LinksUseful.vue"),
    },
    {
      path: "/useful-links-categories",
      name: "useful-links-categories",
      // lazy-loaded
      component: () => import("../views/LinksUsefulCategories.vue"),
    },
    {
      path: "/useful-links-users",
      name: "useful-links-users",
      // lazy-loaded
      component: () => import("../views/LinksUsefulUsers.vue"),
    },
    {
      path: "/messages",
      name: "messages",
      // lazy-loaded
      component: () => import("../views/Messages.vue"),
    },
    {
      path: "/product-search",
      name: "product-search",
      // lazy-loaded
      component: () => import("../views/ProductSearch.vue"),
    },
    {
      path: "/pat-details/:id",
      name: "pat-details",
      // lazy-loaded
      component: () => import("../views/PatDetails.vue"),
    },
    {
      path: "/support-map",
      name: "support-map",
      // lazy-loaded
      component: () => import("../views/SupportMap.vue"),
    },
    {
      path: "/support-map/:date",
      // name: 'support-map',
      // lazy-loaded
      component: () => import("../views/SupportMap.vue"),
    },
    {
      path: "/search/:term",
      //name: 'search',
      // lazy-loaded
      component: () => import("../views/Search.vue"),
    },
    {
      path: "/search/",
      name: "search",
      // lazy-loaded
      component: () => import("../views/Search.vue"),
    },
    {
      path: "/training/",
      name: "training",
      // lazy-loaded
      component: () => import("../views/TrainingActions.vue"),
    },
    {
      path: "/training-needs/",
      name: "training-needs",
      // lazy-loaded
      component: () => import("../views/TrainingNeeds.vue"),
    },
    {
      path: "/my-training/",
      name: "my-training",
      // lazy-loaded
      component: () => import("../views/TrainingParticipation.vue"),
    },
    {
      path: "/my-training-evaluation/",
      name: "my-training-evaluation",
      // lazy-loaded
      component: () => import("../views/TrainingEvaluation.vue"),
    },
    {
      path: "/training-resume/",
      name: "training-resume",
      // lazy-loaded
      component: () => import("../views/TrainingResume.vue"),
    },
    {
      path: "/interview-sheet/",
      name: "interview-sheet",
      // lazy-loaded
      component: () => import("../views/InterviewSheet.vue"),
    },
    {
      path: "/interview-sheet-view/:interviewId",
      // lazy-loaded
      component: () => import("../views/InterviewSheetView.vue"),
    },
    {
      path: "/crm/prospect",
      name: "crm-prospect",
      // lazy-loaded
      component: () => import("../views/CrmProspect.vue"),
    },
    {
      path: "/manuals",
      name: "manuals",
      // lazy-loaded
      component: () => import("../views/Manuals.vue"),
    },
    {
      path: "/manuals-categories",
      name: "manuals-categories",
      // lazy-loaded
      component: () => import("../views/ManualsCategories.vue"),
    },
    {
      path: "/installations/:status?",
      name: "installations",
      // lazy-loaded
      component: () => import("../views/Installations.vue"),
    },
    {
      path: "/installation-details/:id",
      name: "installation-details",
      // lazy-loaded
      component: () => import("../views/InstallationDetail.vue"),
    },
    {
      path: "/assists/:status?",
      name: "assists",
      // lazy-loaded
      component: () => import("../views/Assists.vue"),
    },
    {
      path: "/hours-inactive-users",
      name: "hours-inactive-users",
      // lazy-loaded
      component: () => import("../views/HoursInactiveUsers.vue"),
    },
    {
      path: "/helpdesk-tickets",
      name: "helpdesk-tickets",
      // lazy-loaded
      component: () => import("../views/HelpdeskTickets.vue"),
    },
    {
      path: "/helpdesk-tickets-detail/:ticketId",
      name: "helpdesk-tickets-detail",
      // lazy-loaded
      component: () => import("../views/HelpdeskTicketsDetail.vue"),
    },
    {
      path: "/helpdesk-emails",
      name: "helpdesk-emails",
      // lazy-loaded
      component: () => import("../views/HelpdeskEmails.vue"),
    },
    {
      path: "/proposals",
      name: "proposals",
      meta: {
        permissions: "seeProposals",
      },
      component: () => import("../views/Proposals.vue"),
    },
    {
      path: "/proposal-detail/:proposalId",
      name: "proposal-detail",
      // lazy-loaded
      component: () => import("../views/ProposalDetail.vue"),
    },
    {
      path: "/acls",
      name: "acls",
      // lazy-loaded
      component: () => import("../views/Acls.vue"),
      meta: {
        permissions: "seeAcls",
      },
    },
    {
      path: "/acls-categories",
      name: "acls-categories",
      // lazy-loaded
      component: () => import("../views/AclsCategories.vue"),
      meta: {
        permissions: "seeAclsCategories",
      },
    },
    {
      path: "/acls-groups",
      name: "acls-groups",
      // lazy-loaded
      component: () => import("../views/AclsGroups.vue"),
      meta: {
        permissions: "seeAclsGroups",
      },
    },
    {
      path: "/acls-users",
      name: "acls-users",
      // lazy-loaded
      component: () => import("../views/AclsUsers.vue"),
      meta: {
        permissions: "viewUserGroupAndAcls",
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      // lazy-loaded
      component: () => import("../views/Contacts.vue"),
      // meta: {
      //   permissions: "viewUserGroupAndAcls",
      // },
    },
    {
      path: "/extranet-users",
      name: "extranet-users",
      // lazy-loaded
      component: () => import("../views/ExtranetUsers.vue"),
      meta: {
        permissions: "seeExtranetUsers",
      },
    },
    {
      path: "/extranet-groups",
      name: "extranet-groups",
      // lazy-loaded
      component: () => import("../views/ExtranetGroups.vue"),
      meta: {
        permissions: "seeExtranetGroups",
      },
    },
    {
      path: "/helpdesk-blocked-emails",
      name: "helpdesk-blocked-emails",
      // lazy-loaded
      component: () => import("../views/HelpdeskBlockedEmails.vue"),
      meta: {
        permissions: "seeBlockedEmails",
      },
    },
    {
      path: "/helpdesk-blocked-subjects",
      name: "helpdesk-blocked-subjects",
      // lazy-loaded
      component: () => import("../views/HelpdeskBlockedSubjects.vue"),
      meta: {
        permissions: "seeBlockedSubjects",
      },
    },
    {
      path: "/vehicles",
      name: "vehicles",
      // lazy-loaded
      component: () => import("../views/FleetVehicles.vue"),
      meta: {
        permissions: "seeFleetVehicles",
      },
    },
    {
      path: "/vehicle-details/:id",
      name: "vehicle-details",
      // lazy-loaded
      component: () => import("../views/FleetVehiclesDetails.vue"),
      meta: {
        permissions: "seeFleetVehicleDetails",
      },
    },
    {
      path: "/human-resources-report",
      name: "human-resources-report",
      // lazy-loaded
      component: () => import("../views/HumanResourcesReport.vue"),
      // meta: {
      //   permissions: "seeFleetVehicleDetails",
      // },
    },
    {
      path: "/proposals-unread-messages",
      name: "proposals-unread-messages",
      // lazy-loaded
      component: () => import("../views/ProposalsMessagesUnread.vue"),
      meta: {
        permissions: "seeUnreadEmailsProposals",
      },
    },
    {
      path: "/crm",
      name: "crm",
      // lazy-loaded
      component: () => import("../views/crm.vue"),
      meta: {
        permissions: "seeCRM",
      },
    },
    {
      path: "/crmV2",
      name: "crmV2",
      // lazy-loaded
      component: () => import("../views/crmV2.vue"),
      meta: {
        permissions: "seeCRMV2",
      },
    },
    {
      path: "/crm/lead/:id",
      name: "crm-lead",
      // lazy-loaded
      component: () => import("../views/CrmLead.vue"),
      meta: {
        permissions: "seeLeadCrm",
      },
    },
    {
      path: "/business-classification",
      name: "business-classification",
      // lazy-loaded
      component: () => import("../views/BusinessClassification.vue"),
      meta: {
        permissions: "seeBusinessClassification",
      },
    },
    {
      path: "/business-area",
      name: "business-area",
      // lazy-loaded
      component: () => import("../views/BusinessArea.vue"),
      meta: {
        permissions: "seeBusinessArea",
      },
    },
    {
      path: "/crm/graphics",
      name: "crm-graphics",
      // lazy-loaded
      component: () => import("../views/CrmGraphics.vue"),
      meta: {
        permissions: "seeCRMGraphics",
      },
    },
    {
      path: "/crm/admin/client-types",
      name: "crm-client-types-admin",
      // lazy-loaded
      component: () => import("../views/CrmAdminClientType.vue"),
      meta: {
        permissions: "createCrmClientTypes",
      },
    },
    {
      path: "/crm/admin/categories",
      name: "crm-categories-admin",
      // lazy-loaded
      component: () => import("../views/CrmAdminCategories.vue"),
      meta: {
        permissions: "createCrmCategories",
      },
    },
    {
      path: "/support/bookings",
      name: "support/bookings",
      // lazy-loaded
      component: () => import("../views/SupportBookings.vue"),
      meta: {
        permissions: "seeBookinglistMenu",
      },
    },
    {
      path: "/business-portfolio",
      name: "business-portfolio",
      // lazy-loaded
      component: () => import("../views/BusinessPortfolio.vue"),
      meta: {
        permissions: "seeBusinessPortfolio",
      },
    },
    {
      path: "/business/classification/:id",
      name: "/business-classification-view",
      // lazy-loaded
      component: () => import("../views/BusinessClassificationView.vue"),
      meta: {
        permissions: "seeBusinessPortfolio",
      },
    },
    {
      path: "/extra-hours",
      name: "extra-hours",
      // lazy-loaded
      component: () => import("../views/ExtraHours.vue"),
      meta: {
        permissions: "seeExtraHours",
      },
    },
    {
      path: "/extra-hours-approve",
      name: "extra-hours-approve",
      // lazy-loaded
      component: () => import("../views/ExtraHoursApprove.vue"),
      meta: {
        permissions: "approveExtraHours",
      },
    },
    {
      path: "/extra-hours-process",
      name: "extra-hours-process",
      // lazy-loaded
      component: () => import("../views/ExtraHoursProcess.vue"),
      meta: {
        permissions: "processExtraHours",
      },
    },
    {
      path: "/training/:trainingId/userEvaluation/user/:userId",
      name: "training-userEvaluation",
      // lazy-loaded
      component: () => import("../views/TrainingUserEvaluation.vue"),
    },
    {
      path: "/booking/unavailability/toApprove",
      name: "booking-unavailability-toApprove",
      // lazy-loaded
      component: () => import("../views/UnavailabilityToApprove.vue"),
      meta: {
        permissions: "approvesUnavailability",
      },
    },
    {
      path: "/booking/unavailability",
      name: "booking-unavailability",
      // lazy-loaded
      component: () => import("../views/Unavailability.vue"),
    },
    {
      path: "/expiringContracts",
      name: "expiringContracts",
      // lazy-loaded
      component: () => import("../views/ExpiringContracts.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && (!loggedIn || loggedIn == null)) {
    next("/login");
  } else {
    var userPermissions = [];
    if (loggedIn != undefined && JSON.parse(loggedIn).moreInfo != undefined) {
      userPermissions = loggedIn
        ? JSON.parse(window.atob(JSON.parse(loggedIn).moreInfo))
        : [];
      if (to.meta.permissions) {
        let isAllowed = userPermissions.find(
          (acl) => acl === to.meta.permissions
        );

        if (!isAllowed) {
          return next("/");
        }
      }
    }
    next();
  }
});
